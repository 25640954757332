//
//
//
//
//
//

// import store from '@/store'
export default {
  name: 'App',
  provide: function provide() {
    return {
      reload: this.reload
    };
  },
  data: function data() {
    return {
      clickTime: new Date().getTime(),
      tOut: 60 * 60 * 1000,
      isRouterAlive: true
    };
  },
  methods: {
    clicked: function clicked() {
      var _this = this;
      if (new Date().getTime() - this.clickTime > this.tOut) {
        if (this.$store.getters.token) {
          this.$store.dispatch('user/logout').then(function () {
            _this.$alert('超时未操作，请重新登录。', '提示', {
              confirmButtonText: '确定'
            }).then(function () {
              location.reload();
            });
          });
        }
      }
      this.clickTime = new Date().getTime();
    },
    reload: function reload() {
      this.isRouterAlive = false;
      this.$nextTick(function () {
        this.isRouterAlive = true;
      });
    }
  }
};