import "core-js/modules/es7.symbol.async-iterator";
import "core-js/modules/es6.symbol";
import "core-js/modules/es6.string.iterator";
import "core-js/modules/es6.array.from";
import "core-js/modules/es6.regexp.to-string";
import _toConsumableArray from "/root/workspace/dst3.0_front_vGcT/node_modules/_@babel_runtime-corejs2@7.24.4@@babel/runtime-corejs2/helpers/esm/toConsumableArray.js";
import _slicedToArray from "/root/workspace/dst3.0_front_vGcT/node_modules/_@babel_runtime-corejs2@7.24.4@@babel/runtime-corejs2/helpers/esm/slicedToArray.js";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.function.name";
import "core-js/modules/es7.array.includes";
import "core-js/modules/es6.string.includes";
function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
var state = {
  visitedViews: [],
  cachedViews: []
};
var mutations = {
  ADD_VISITED_VIEW: function ADD_VISITED_VIEW(state, view) {
    if (state.visitedViews.some(function (v) {
      return v.path === view.path;
    })) return;
    state.visitedViews.push(Object.assign({}, view, {
      title: view.meta.title || 'no-name'
    }));
  },
  ADD_CACHED_VIEW: function ADD_CACHED_VIEW(state, view) {
    if (state.cachedViews.includes(view.name)) return;
    if (!view.meta.noCache) {
      state.cachedViews.push(view.name);
    }
  },
  DEL_VISITED_VIEW: function DEL_VISITED_VIEW(state, view) {
    var _iterator = _createForOfIteratorHelper(state.visitedViews.entries()),
      _step;
    try {
      for (_iterator.s(); !(_step = _iterator.n()).done;) {
        var _step$value = _slicedToArray(_step.value, 2),
          i = _step$value[0],
          v = _step$value[1];
        if (v.path === view.path) {
          state.visitedViews.splice(i, 1);
          break;
        }
      }
    } catch (err) {
      _iterator.e(err);
    } finally {
      _iterator.f();
    }
  },
  DEL_CACHED_VIEW: function DEL_CACHED_VIEW(state, view) {
    var index = state.cachedViews.indexOf(view.name);
    index > -1 && state.cachedViews.splice(index, 1);
  },
  DEL_OTHERS_VISITED_VIEWS: function DEL_OTHERS_VISITED_VIEWS(state, view) {
    state.visitedViews = state.visitedViews.filter(function (v) {
      return v.meta.affix || v.path === view.path;
    });
  },
  DEL_OTHERS_CACHED_VIEWS: function DEL_OTHERS_CACHED_VIEWS(state, view) {
    var index = state.cachedViews.indexOf(view.name);
    if (index > -1) {
      state.cachedViews = state.cachedViews.slice(index, index + 1);
    } else {
      // if index = -1, there is no cached tags
      state.cachedViews = [];
    }
  },
  DEL_ALL_VISITED_VIEWS: function DEL_ALL_VISITED_VIEWS(state) {
    // keep affix tags
    var affixTags = state.visitedViews.filter(function (tag) {
      return tag.meta.affix;
    });
    state.visitedViews = affixTags;
  },
  DEL_ALL_CACHED_VIEWS: function DEL_ALL_CACHED_VIEWS(state) {
    state.cachedViews = [];
  },
  UPDATE_VISITED_VIEW: function UPDATE_VISITED_VIEW(state, view) {
    var _iterator2 = _createForOfIteratorHelper(state.visitedViews),
      _step2;
    try {
      for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
        var v = _step2.value;
        if (v.path === view.path) {
          v = Object.assign(v, view);
          break;
        }
      }
    } catch (err) {
      _iterator2.e(err);
    } finally {
      _iterator2.f();
    }
  }
};
var actions = {
  addView: function addView(_ref, view) {
    var dispatch = _ref.dispatch;
    dispatch('addVisitedView', view);
    dispatch('addCachedView', view);
  },
  addVisitedView: function addVisitedView(_ref2, view) {
    var commit = _ref2.commit;
    commit('ADD_VISITED_VIEW', view);
  },
  addCachedView: function addCachedView(_ref3, view) {
    var commit = _ref3.commit;
    commit('ADD_CACHED_VIEW', view);
  },
  delView: function delView(_ref4, view) {
    var dispatch = _ref4.dispatch,
      state = _ref4.state;
    return new Promise(function (resolve) {
      dispatch('delVisitedView', view);
      dispatch('delCachedView', view);
      resolve({
        visitedViews: _toConsumableArray(state.visitedViews),
        cachedViews: _toConsumableArray(state.cachedViews)
      });
    });
  },
  delVisitedView: function delVisitedView(_ref5, view) {
    var commit = _ref5.commit,
      state = _ref5.state;
    return new Promise(function (resolve) {
      commit('DEL_VISITED_VIEW', view);
      resolve(_toConsumableArray(state.visitedViews));
    });
  },
  delCachedView: function delCachedView(_ref6, view) {
    var commit = _ref6.commit,
      state = _ref6.state;
    return new Promise(function (resolve) {
      commit('DEL_CACHED_VIEW', view);
      resolve(_toConsumableArray(state.cachedViews));
    });
  },
  delOthersViews: function delOthersViews(_ref7, view) {
    var dispatch = _ref7.dispatch,
      state = _ref7.state;
    return new Promise(function (resolve) {
      dispatch('delOthersVisitedViews', view);
      dispatch('delOthersCachedViews', view);
      resolve({
        visitedViews: _toConsumableArray(state.visitedViews),
        cachedViews: _toConsumableArray(state.cachedViews)
      });
    });
  },
  delOthersVisitedViews: function delOthersVisitedViews(_ref8, view) {
    var commit = _ref8.commit,
      state = _ref8.state;
    return new Promise(function (resolve) {
      commit('DEL_OTHERS_VISITED_VIEWS', view);
      resolve(_toConsumableArray(state.visitedViews));
    });
  },
  delOthersCachedViews: function delOthersCachedViews(_ref9, view) {
    var commit = _ref9.commit,
      state = _ref9.state;
    return new Promise(function (resolve) {
      commit('DEL_OTHERS_CACHED_VIEWS', view);
      resolve(_toConsumableArray(state.cachedViews));
    });
  },
  delAllViews: function delAllViews(_ref10, view) {
    var dispatch = _ref10.dispatch,
      state = _ref10.state;
    return new Promise(function (resolve) {
      dispatch('delAllVisitedViews', view);
      dispatch('delAllCachedViews', view);
      resolve({
        visitedViews: _toConsumableArray(state.visitedViews),
        cachedViews: _toConsumableArray(state.cachedViews)
      });
    });
  },
  delAllVisitedViews: function delAllVisitedViews(_ref11) {
    var commit = _ref11.commit,
      state = _ref11.state;
    return new Promise(function (resolve) {
      commit('DEL_ALL_VISITED_VIEWS');
      resolve(_toConsumableArray(state.visitedViews));
    });
  },
  delAllCachedViews: function delAllCachedViews(_ref12) {
    var commit = _ref12.commit,
      state = _ref12.state;
    return new Promise(function (resolve) {
      commit('DEL_ALL_CACHED_VIEWS');
      resolve(_toConsumableArray(state.cachedViews));
    });
  },
  updateVisitedView: function updateVisitedView(_ref13, view) {
    var commit = _ref13.commit;
    commit('UPDATE_VISITED_VIEW', view);
  }
};
export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};