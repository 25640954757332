import store from '@/store';
import { setToken } from '@/utils/auth';
import { MessageBox } from 'element-ui';
import user from '@/api/user';
function refreshToken() {
  return new Promise(function (resolve, reject) {
    var getRefreshToken = localStorage.getItem('setToken');
    user.refresh(getRefreshToken).then(function (response) {
      if (response.data.error === 'invalid_token') {
        store.dispatch('user/logout').then(function () {
          MessageBox.confirm('令牌缺失，请重新登录。', {
            confirmButtonText: '重新登录',
            type: 'warning'
          }).then(function () {
            location.reload();
          });
        });
      } else {
        var data = response.data;
        var setTokenAgain = data.token_type + ' ' + data.access_token;
        var getId = data.userId;
        // 存储刷新token
        var setRefreshToken = data.refresh_token;
        var getTime = data.expires_in;
        localStorage.setItem('setToken', setRefreshToken);
        localStorage.setItem('getTime', getTime);
        localStorage.setItem('setIds', getId);
        setToken(setTokenAgain);
        resolve();
      }
    }).catch(function (error) {
      reject(error);
    });
  });
}
export { refreshToken };