import "core-js/modules/es7.object.get-own-property-descriptors";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.object.keys";
import _defineProperty from "/root/workspace/dst3.0_front_vGcT/node_modules/_@babel_runtime-corejs2@7.24.4@@babel/runtime-corejs2/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
/*
 * @Descripttion:
 * @Author: dan_winn
 * @Date: 2019-12-16 09:14:03
 * @LastEditors: dan_winn
 * @LastEditTime: 2020-02-26 16:10:26
 */
import axios from 'axios';
import setting from '@/settings';
import { MessageBox, Message } from 'element-ui';
import store from '@/store';
// import { getToken, setToken, removeToken } from '@/utils/auth'
import { getToken } from '@/utils/auth';
import { refreshToken } from '@/utils/refreshToken';
// 创建一个AXIOS实例
var service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  // url = base url + request url
  // withCredentials: true, // 跨域请求时发送cookies
  timeout: 20 * 60 * 1000 // request timeout
});

// 请求拦截器
service.interceptors.request.use(function (config) {
  if (store.getters.token) {
    // 让每个请求携带令牌
    config.headers['DeepSight-Auth'] = getToken();
    config.headers['Deepsight_Auth_Id'] = localStorage.getItem('setIds');
    config.headers['n-d-version'] = setting.version;
    // store.dispatch('settings/changeSetting', {
    //   key: 'isRpeatClick',
    //   value: true
    // })
  }
  if (config.method === 'get') {
    config.params = _objectSpread({
      _t: Date.parse(new Date()) / 1000
    }, config.params);
  }
  return config;
}, function (error) {
  return Promise.reject(error);
});

// 响应拦截器
service.interceptors.response.use(
/**
 *获取诸如头或状态之类的http信息
 *请返回response=>response
 *通过自定义代码确定请求状态
 *这里只是一个例子
 *通过HTTP状态代码判断状态
 */
function (response) {
  store.dispatch('settings/changeSetting', {
    key: 'isRpeatClick',
    value: false
  });
  var res = response.data;
  if (res.code === 405) {
    return res;
  }
  if (res.code !== 200) {
    Message.error(res.msg || '网络超时或重复请求，请稍后重试！' || res.message);
    return res;
  } else {
    return res;
  }
}, function (error) {
  if (error && error.response) {
    store.dispatch('settings/changeSetting', {
      key: 'isRpeatClick',
      value: false
    });
    switch (error.response.status) {
      case 401:
        refreshToken();
        break;
      case 403:
        store.dispatch('user/logout').then(function () {
          MessageBox.confirm('您已被禁用，请联系管理员！！！', {
            confirmButtonText: '重新登录',
            type: 'warning'
          }).then(function () {
            location.reload();
          });
        });
        break;
      case 404:
        Message({
          message: '请求无效，请联系管理员！！！',
          type: 'error'
        });
        break;
      case 500:
        Message.error('系统错误，请联系管理员！！！');
        // store.dispatch("user/logout").then(() => {
        //   Message({
        //     message: '系统错误，请联系管理员！！！',
        //     type: 'error'
        //   })
        // });
        break;
      default:
        error.message = "\u8FDE\u63A5\u51FA\u9519(".concat(error.response.status, ")!");
    }
  }
  return Promise.reject(error);
});
export default service;