import Vue from 'vue';
import Router from 'vue-router';
Vue.use(Router);

/* Layout */
import Layout from '@/layout';

/* Router Modules */
// import chartsRouter from './modules/charts'

/**
*注意：子菜单仅在route children.length>=1时出现
*详细信息请参见：https://panjiachen.github.io/vue-element-admin-site/guide/essenties/router-and-nav.html
*隐藏：true如果设置为true，则项目将不会显示在侧边栏中（默认为false）
*always show:true如果设置为true，将始终显示根菜单
*如果不始终设置Show，则当项具有多个子路由时，
*它将成为嵌套模式，否则不显示根菜单
*重定向：如果设置了no redirect，则noRedirect将不会在breadcrumb中重定向
*name:“router-name”该名称由<keep alive>使用（必须设置！!!)
*root menu：{
角色：['admin'，'editor']控制页面角色（您可以设置多个角色）
title:“title”在侧边栏和面包屑中显示的名称（推荐集）
icon:'svg name'图标显示在侧栏中
noCache:true如果设置为true，则不会缓存该页（默认为false）
附加：真如果设置为真，标签将附加在标签视图中
breadcrumb:false如果设置为false，则该项将隐藏在breadcrumb中（默认为true）
activeMenu:'/example/list'如果设置路径，侧边栏将突出显示您设置的路径
}
*/

/**
 * constantRoutes
 *没有权限要求的基页
 *可以访问所有角色
 */
export var constantRoutes = [{
  path: '/redirect',
  component: Layout,
  hidden: true,
  children: [{
    path: '/redirect/:path(.*)',
    component: function component() {
      return import('@/views/redirect/index');
    }
  }]
}, {
  path: '/login',
  component: function component() {
    return import('@/views/login/index');
  },
  hidden: true
}, {
  path: '/auth-redirect',
  component: function component() {
    return import('@/views/login/auth-redirect');
  },
  hidden: true
}, {
  path: '/404',
  component: function component() {
    return import('@/views/error-page/404');
  },
  hidden: true
}, {
  path: '/401',
  component: function component() {
    return import('@/views/error-page/401');
  },
  hidden: true
}, {
  path: '/',
  component: Layout,
  redirect: '/dashboard',
  children: [{
    path: 'dashboard',
    component: function component(resolve) {
      return require(['@/views/dashboard/index'], resolve);
    },
    name: 'Dashboard',
    meta: {
      title: '首页',
      icon: 'dashboard',
      affix: true
    }
  }]
}, {
  path: '/dealerConsumables',
  component: Layout,
  // redirect: '/dealer-consumables-manage/unit-price-consumables',
  hidden: true,
  children: [{
    path: 'detail',
    component: function component() {
      return import('@/views/dealer-consumables-manage/unit-price-consumables/detail');
    },
    name: 'ConsumablesDetail',
    meta: {
      title: '耗材特殊单价'
    }
  }]
}, {
  path: '/profile',
  component: Layout,
  redirect: '/profile/index',
  hidden: true,
  children: [{
    path: 'index',
    component: function component() {
      return import('@/views/profile/index');
    },
    name: 'Profile',
    meta: {
      title: '个人信息',
      icon: 'user',
      noCache: false
    }
  }]
},
// 医院详情
{
  path: '/cur-hos',
  component: Layout,
  redirect: '/hos/hospital',
  hidden: true,
  children: [{
    path: 'json',
    component: function component() {
      return import('@/views/hos/hospital/json');
    },
    name: 'Json',
    meta: {
      title: '报告配置',
      noCache: true
    }
  }, {
    path: 'detail/:id',
    component: function component() {
      return import('@/views/hos/hospital/detail');
    },
    name: 'HospitalDetail',
    meta: {
      title: '医院详情',
      noCache: false
    }
  }, {
    path: 'upload-excel',
    component: function component() {
      return import('@/views/hos/hospital/upload-excel');
    },
    name: 'UploadExcel',
    meta: {
      title: '批量导入医院',
      noCache: false
    }
  }]
},
// 经销商-医院详情
{
  path: '/dealerHos',
  component: Layout,
  redirect: '/dealer-hos/dealer-hospital-list',
  hidden: true,
  children: [{
    path: 'detail/:id',
    component: function component() {
      return import('@/views/dealer-hos/dealer-hospital-list/detail');
    },
    name: 'dealerDetail',
    meta: {
      title: '医院详情',
      noCache: false
    }
  }]
},
// 掌上自检局 订单分析 按商品规格
{
  path: '/marketing-order-analysis',
  component: Layout,
  redirect: '/palmwxapp/wx-marketing-analysis/order-analysis/index',
  hidden: true,
  children: [{
    path: 'by-specs',
    component: function component() {
      return import('@/views/palmwxapp/wx-marketing-analysis/order-analysis/by-specs');
    },
    name: 'BySpecsPage',
    meta: {
      title: '订单分析-按商品规格'
    }
  }]
},
// 掌旋 销售趋势 按医院
{
  path: '/vaccine-sales-trend',
  component: Layout,
  redirect: '/vaccine/marketing-analysis/sales-trend/index',
  hidden: true,
  children: [{
    path: 'by-pov',
    component: function component() {
      return import('@/views/vaccine/marketing-analysis/sales-trend/by-pov');
    },
    name: 'VaccineSalesTrendByPOV',
    meta: {
      title: '销售趋势按医院查看'
    }
  }]
},
// 掌旋 - POV管理
{
  path: '/vaccine-POV-manage',
  component: Layout,
  redirect: '/vaccine/POV/index',
  hidden: true,
  children: [{
    path: 'sale-user-manage',
    component: function component() {
      return import('@/views/vaccine/POV/saleUserManage');
    },
    name: 'SaleUserManage',
    meta: {
      title: '负责人管理'
    }
  }]
}, {
  path: '/sales-trend',
  component: Layout,
  redirect: '/sales-trend/index',
  hidden: true,
  children: [{
    path: 'hos-list',
    component: function component() {
      return import('@/views/sales-analysis/sales-trend/hos-list');
    },
    name: 'SalesHosList',
    meta: {
      title: '销售趋势按医院查看'
    }
  }, {
    path: 'department-trend',
    component: function component() {
      return import('@/views/sales-analysis/sales-trend/department-trend');
    },
    name: 'DepartmentTrend',
    meta: {
      title: '销售趋势按部门查看'
    }
  }, {
    path: 'dealer-trend-list',
    component: function component() {
      return import('@/views/sales-analysis/sales-trend/dealer-trend-list');
    },
    name: 'SalesDealerList',
    meta: {
      title: '销售趋势按经销商查看'
    }
  }, {
    path: 'hos-details',
    component: function component() {
      return import('@/views/sales-analysis/sales-trend/hos-details');
    },
    name: 'SalesHosDetails',
    meta: {
      title: '医院销售趋势'
    }
  }]
},
// 阅片
{
  path: '/cur-film',
  component: Layout,
  redirect: '/film/ai',
  hidden: true,
  children: [{
    path: '/transfer/:action',
    component: function component() {
      return import('@/views/film/detail/transfer');
    },
    name: 'Transfer',
    meta: {
      title: '复核详情',
      noCache: true
    }
  }, {
    path: 'edit',
    component: function component() {
      return import('@/views/film/detail/edit');
    },
    name: 'FilmEdit',
    meta: {
      title: '复核详情',
      noCache: false
    }
  }, {
    path: 'edit-openseadragon',
    component: function component() {
      return import('@/views/film/detail/edit-openseadragon');
    },
    name: 'FilmEditOpenseadragon',
    meta: {
      title: '复核详情',
      noCache: true
    }
  }, {
    path: 'manage',
    component: function component() {
      return import('@/views/film/detail/manage');
    },
    name: 'FilmManage',
    meta: {
      title: '复核管理详情',
      noCache: true
    }
  }, {
    path: 'manage-openseadragon',
    component: function component() {
      return import('@/views/film/detail/manage-openseadragon');
    },
    name: 'FilmManageOpenseadragon',
    meta: {
      title: '复核管理详情',
      noCache: true
    }
  }, {
    path: 'read',
    component: function component() {
      return import('@/views/film/detail/read');
    },
    name: 'FilmRead',
    meta: {
      title: '查看详情',
      noCache: true
    }
  }, {
    path: 'read-openseadragon',
    component: function component() {
      return import('@/views/film/detail/read-openseadragon');
    },
    name: 'FilmReadOpenseadragon',
    meta: {
      title: '查看详情',
      noCache: true
    }
  }, {
    path: 'qc-diagnosis-list',
    component: function component() {
      return import('@/views/film/reviewing/qc-diagnosis-list');
    },
    name: 'QcDiagnosis',
    meta: {
      title: '质控诊断列表',
      noCache: true
    }
  }, {
    path: '/transfer/qc/:action',
    component: function component() {
      return import('@/views/film/reviewing/qc/transfer-qc');
    },
    name: 'TransferQc',
    meta: {
      title: '复核详情',
      noCache: true
    }
  }, {
    path: 'edit-qc',
    component: function component() {
      return import('@/views/film/reviewing/qc/edit-qc');
    },
    name: 'FilmEditQc',
    meta: {
      title: '复核详情',
      noCache: false
    }
  }, {
    path: 'edit-openseadragon-qc',
    component: function component() {
      return import('@/views/film/reviewing/qc/edit-openseadragon-qc');
    },
    name: 'FilmEditOpenseadragonQc',
    meta: {
      title: '复核详情',
      noCache: true
    }
  }]
}, {
  path: '/result-check',
  component: Layout,
  redirect: '/result-check/index',
  hidden: true,
  children: [{
    path: 'view-report',
    component: function component() {
      return import('@/views/film-check/result-check/view-report');
    },
    name: 'viewReport',
    meta: {
      title: '预览报告'
    }
  }]
},
// 标记 视野详情
{
  path: '/signViewList',
  component: Layout,
  redirect: '/signViewList/detail',
  hidden: true,
  children: [{
    path: 'detail',
    component: function component() {
      return import('@/views/signSystem/signViewList/detail');
    },
    name: 'SignViewListDetail',
    meta: {
      title: '标记视野详情',
      noCache: true
    }
  }]
}, {
  path: '/dailyList',
  component: Layout,
  redirect: '/dailyList/dailyInfo',
  hidden: true,
  children: [{
    path: 'dailyInfo',
    component: function component() {
      return import('@/views/workReport/dailyList/dailyInfo');
    },
    name: '日报详情',
    meta: {
      title: '日报详情',
      icon: 'user',
      noCache: true
    }
  }]
},
// 项目管理
{
  path: '/projectList',
  component: Layout,
  redirect: '/project-list/price',
  hidden: true,
  children: [{
    path: 'price',
    component: function component() {
      return import('@/views/product/project-list/price');
    },
    name: '特殊价格设置',
    meta: {
      title: '特殊价格设置',
      icon: 'user',
      noCache: true
    }
  }, {
    path: 'perPrice',
    component: function component() {
      return import('@/views/product/project-list/perPrice');
    },
    name: 'PerPrice',
    meta: {
      title: '单价设置',
      icon: 'user',
      noCache: false
    }
  }, {
    path: 'priceBatchSet',
    component: function component() {
      return import('@/views/product/project-list/priceBatchSet');
    },
    name: 'PriceBatchSet',
    meta: {
      title: '批量单价设置',
      icon: 'user',
      noCache: false
    }
  }]
},
// 项目套餐管理
{
  path: '/projectPackageList',
  component: Layout,
  redirect: '/project-list/price',
  hidden: true,
  children: [{
    path: 'price',
    component: function component() {
      return import('@/views/product/project-package-m/price');
    },
    name: 'Price2',
    meta: {
      title: '特殊价格设置',
      icon: 'user',
      noCache: true
    }
  }, {
    path: 'perPrice',
    component: function component() {
      return import('@/views/product/project-package-m/perPrice');
    },
    name: 'PerPrice1',
    meta: {
      title: '单价设置',
      icon: 'user',
      noCache: false
    }
  }, {
    path: 'priceBatchSet',
    component: function component() {
      return import('@/views/product/project-package-m/priceBatchSet');
    },
    name: 'PriceBatchSet1',
    meta: {
      title: '批量单价设置',
      icon: 'user',
      noCache: false
    }
  }]
},
// 经销商管理
{
  path: '/palmwxappOrderList',
  component: Layout,
  redirect: '/palmwxapp/order-man/order-list',
  hidden: true,
  children: [{
    path: 'advanceOrder-details',
    component: function component() {
      return import('@/views/palmwxapp/order-man/order-list/order-details');
    },
    name: 'SetPriceList1',
    meta: {
      title: '订单管理',
      icon: 'user',
      noCache: true
    }
  }]
},
// 订单详情
{
  path: '/dealerList',
  component: Layout,
  redirect: '/dealer-list/index',
  hidden: true,
  children: [{
    path: 'set-price-list',
    component: function component() {
      return import('@/views/dealer-manage/dealer-list/set-price-list');
    },
    name: 'SetPriceList',
    meta: {
      title: '项目价格配置',
      icon: 'user',
      noCache: true
    }
  }, {
    path: 'set-package-list',
    component: function component() {
      return import('@/views/dealer-manage/dealer-list/set-package-list');
    },
    name: 'SetPackageList',
    meta: {
      title: '套餐价格配置',
      icon: 'user',
      noCache: true
    }
  }, {
    path: 'batch-settings-upload-excel',
    component: function component() {
      return import('@/views/dealer-manage/dealer-list/batch-settings-upload-excel');
    },
    name: 'BatchSettingsUploadExcel',
    meta: {
      title: '批量设置',
      icon: 'user',
      noCache: true
    }
  }]
},
// 收入分析详情
{
  path: '/incomeOverview',
  component: Layout,
  // redirect: '/revenueOverview/components',
  hidden: true,
  children: [{
    path: 'consumable-income',
    component: function component() {
      return import('@/views/Income-analysis/revenueOverview/components/consumable-income');
    },
    name: 'ConsumableIncome',
    meta: {
      title: '耗材收入'
    }
  }, {
    path: 'logistics-income',
    component: function component() {
      return import('@/views/Income-analysis/revenueOverview/components/logistics-income');
    },
    name: 'LogisticsIncome',
    meta: {
      title: '物流收入'
    }
  }, {
    path: 'scan-income',
    component: function component() {
      return import('@/views/Income-analysis/revenueOverview/components/scan-income');
    },
    name: 'ScanIncome',
    meta: {
      title: '扫片收入'
    }
  }, {
    path: 'hos-income',
    component: function component() {
      return import('@/views/Income-analysis/revenueOverview/components/hos-income');
    },
    name: 'HosIncome',
    meta: {
      title: '共建医院报告收入'
    }
  }, {
    path: 'rc-income',
    component: function component() {
      return import('@/views/Income-analysis/revenueOverview/components/rc-income');
    },
    name: 'RcIncome',
    meta: {
      title: '远程会诊收入'
    }
  }]
},
// 成本管理
{
  path: '/costManage',
  component: Layout,
  hidden: true,
  children: [{
    path: 'consumableMaterial',
    component: function component() {
      return import('@/views/costManagement/consumableMaterial/consumableMaterialManage');
    },
    name: 'ConsumableMaterialManage',
    meta: {
      title: '管理耗材'
    },
    hidden: true
  }, {
    path: 'hospitalCost',
    component: function component() {
      return import('@/views/costManagement/commonHospital/hospitalCost');
    },
    name: 'HospitalCost',
    meta: {
      title: '医院成本管理'
    },
    hidden: true
  }, {
    path: 'dstCost',
    component: function component() {
      return import('@/views/costManagement/commonHospital/dstCost');
    },
    name: 'DstCost',
    meta: {
      title: '迪赛特成本管理'
    },
    hidden: true
  }]
}, {
  path: '/products',
  component: Layout,
  children: [{
    path: 'special-price-set',
    component: function component() {
      return import('@/views/product/special-config-list/special-price-set');
    },
    name: 'SpecialPriceSet',
    meta: {
      title: '特殊单价设置'
    },
    hidden: true
  }]
}];

/**
 *异步路由
 *需要根据用户角色动态加载的路由
 */
export var asyncRoutes = [
// 404页必须放在末尾
{
  path: '*',
  redirect: '/404',
  hidden: true
}];
var createRouter = function createRouter() {
  return new Router({
    // mode: 'history', // 需要服务支持
    scrollBehavior: function scrollBehavior() {
      return {
        y: 0
      };
    },
    routes: constantRoutes
  });
};
var router = createRouter();

// 详情见: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  var newRouter = createRouter();
  router.matcher = newRouter.matcher; // 重置路由器
}
export default router;