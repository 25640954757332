import request from '@/utils/request';
var user = {
  login: function login(data) {
    return request({
      url: '/dst-auth/oauth/login',
      method: 'post',
      data: data
    });
  },
  getInfo: function getInfo(id) {
    return request({
      url: '/dst-system/system/user/getDetailsById',
      method: 'get',
      params: {
        id: id
      }
    });
  },
  getUserByName: function getUserByName(userInfo) {
    return request({
      url: '/dst-system/system/user/pageByUser',
      method: 'get',
      params: userInfo
    });
  },
  // 经销商用户列表
  getDealerUserByName: function getDealerUserByName(userInfo) {
    return request({
      url: '/dst-system/system/user/dealer/pageByUser',
      method: 'get',
      params: userInfo
    });
  },
  getUserByNameCopy: function getUserByNameCopy(userInfo) {
    return request({
      url: '/dst-system/user/listPublicUser',
      method: 'get',
      params: userInfo
    });
  },
  getUserReview: function getUserReview(userInfo) {
    return request({
      url: '/dst-system/user/queryReviewDoctorList',
      method: 'get',
      params: userInfo
    });
  },
  getUserMeeting: function getUserMeeting(userInfo) {
    return request({
      url: '/dst-system/user/queryConsultationDoctorList',
      method: 'get',
      params: userInfo
    });
  },
  logout: function logout() {
    return request({
      url: '/user/logout',
      method: 'post'
    });
  },
  delUser: function delUser(data) {
    return request({
      url: '/dst-system/system/user/removeById?id=' + data,
      method: 'get'
    });
  },
  batchDelUser: function batchDelUser(data) {
    return request({
      url: '/dst-system/system/user/removeByIds?ids=' + data,
      method: 'get'
    });
  },
  getUserList: function getUserList() {
    return request({
      url: '/dst-system/user/selectAll',
      method: 'get'
    });
  },
  updUser: function updUser(data) {
    return request({
      url: '/dst-system/system/user/update',
      method: 'post',
      data: data
    });
  },
  addUser: function addUser(data) {
    return request({
      url: '/dst-system/system/user/save',
      method: 'post',
      data: data
    });
  },
  lockUserApi: function lockUserApi(userId, lock) {
    return request({
      url: '/dst-system/system/user/lockUser?userId=' + userId + '&isLock=' + lock,
      method: 'get'
    });
  },
  getLock: function getLock() {
    return request({
      url: '/dst-system/system/dict/getList?code=status',
      method: 'get'
    });
  },
  getSex: function getSex() {
    return request({
      url: '/dst-system/system/dict/listTree?code=sex',
      method: 'get'
    });
  },
  resetPwd: function resetPwd(data) {
    return request({
      url: '/dst-system/system/user/resetPassword?id=' + data,
      method: 'get'
    });
  },
  // 定时刷新
  refresh: function refresh(data) {
    return request({
      url: '/dst-auth/oauth/refresh?refreshToken=' + data,
      method: 'post'
    });
  },
  editUserPwd: function editUserPwd(data) {
    return request({
      url: '/dst-system/system/user/updatePassword',
      method: 'post',
      data: data
    });
  },
  // 加载首页图表
  loadChartApi: function loadChartApi(data) {
    return request({
      url: '/dst-two-cancer/screening/tc-patient-case/screeningRegistrationStatistics',
      method: 'get'
    });
  }
};
export default user;