import "core-js/modules/es7.array.includes";
import "core-js/modules/es6.string.includes";
import "core-js/modules/web.dom.iterable";
import Vue from 'vue';
var status = false;
function traverse(obj, data) {
  data.forEach(function (item) {
    if (item.codeList) {
      if (item.codeList.includes(obj) === true) {
        status = item.codeList.includes(obj);
        return status;
      }
      if (item.children) {
        traverse(obj, item.children); // 递归遍历
      }
    }
  });
}
var hasPermission = function hasPermission(userPermission) {
  var btnPermissionList = JSON.parse(sessionStorage.getItem('get-btn-routes'));
  status = false;
  traverse(userPermission, btnPermissionList);
  return status;
};
// 全局方法挂载
Vue.prototype.$_has = hasPermission;