import request from '@/utils/request';

// 获取左边侧边栏
var article = {
  // 左边侧边栏信息
  getListMenuTreeByUserIdApi: function getListMenuTreeByUserIdApi(data) {
    return request({
      url: '/dst-system/system/role-menu/listMenuTreeByUserId',
      method: 'get',
      data: data
    });
  },
  // 左边侧边栏信息
  menuRoutes: function menuRoutes(data) {
    return request({
      url: '/dst-system/system/menu/listTree',
      method: 'get',
      data: data
    });
  },
  // 更新token
  resetToken: function resetToken(refreshToken) {
    return request({
      url: '/dst-auth/oauth/refresh',
      method: 'post',
      params: {
        refreshToken: refreshToken
      }
    });
  }
};
export default article;