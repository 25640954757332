import _slicedToArray from "/root/workspace/dst3.0_front_vGcT/node_modules/_@babel_runtime-corejs2@7.24.4@@babel/runtime-corejs2/helpers/esm/slicedToArray.js";
import publicApi from '@/api/public';
var state = {
  dstCustomerId: '1395562462765387777',
  // DST客户id
  // 检查项目id
  tctId: '1395618831419121666',
  // TCT	2
  nonGynecologicCellId: '1395618938818469890',
  // 非妇细胞（液基）	2
  punctureTissueId: '1395619087691096065',
  // 小组织（穿刺组织活检）	1
  endoscopeId: '1395619184072007681',
  // 小组织（内镜组织活检（除胃镜））	1
  gastroscopyId: '1395619316226138114',
  // 小组织（胃镜组织活检）	1
  localExcisionId: '1395619467200110593',
  // 	小组织（局部切除活检组织）	1
  boneMarrowId: '1395619630564057090',
  // 	小组织（骨髓组织活检）	1
  smallSpecimenId: '1395619773594017794',
  // 	小组织（手术小标本（<3cm））	1
  mediumSpecimenId: '1395619884743073794',
  // 	中组织（手术中标本（3-8cm）/单切）	1
  largeSpecimenId: '1395620154382295042',
  // 	大组织（手术大标本（>8cm）/根治）	1
  hpvId: '1395620300398600194',
  // 	HPV	3
  microorganismId: '1395620565499584514',
  // 	微生物三项	3
  bStreptococcusId: '1395620663000375298',
  // 	B族链球菌	3
  folicAcId: '1395620757862948865',
  // 	叶酸	3
  doubleCellsId: '1395620901236842498',
  // 	细胞双染	4
  immunohistochemistryId: '1395620999601659906',
  // 	免疫组化	4
  specialDyeingId: '1395621093667315714',
  // 	特殊染色	4
  generalConsultationId: '1395621203834904578',
  // 	普通病理会诊	5
  difficultConsultationId: '1395621281471471617',
  // 	疑难病理会诊	5
  punctureCellId: '1414513013014564866',
  // 	细针穿刺细胞学（涂片）	2
  whiteSliceId: '1414513242120032258',
  // 	切白片	2
  methylationLungCancerId: '1441280563860185089',
  // 	肺癌甲基化	3
  mpId: '1448951269283241985',
  // 	MP耐药	3
  hpId: '1448951604216569857',
  // 	HP耐药	3
  freezingId: '1474207781787762689',
  // 	快速冰冻	6
  ebId: '1508259250010914817',
  // 	EB病毒检测	3
  E7Id: '1541302807422349314',
  // E7
  exfoliativeId: '1552098840250994689' // 脱落细胞涂片
};
var mutations = {
  SET_STATE_VAR: function SET_STATE_VAR(state, _ref) {
    var _ref2 = _slicedToArray(_ref, 2),
      code = _ref2[0],
      res = _ref2[1];
    state[code] = res;
  }
};
var actions = {
  getDicList: function getDicList(_ref3, code) {
    var state = _ref3.state,
      commit = _ref3.commit;
    // 获取字典
    return new Promise(function (resolve, reject) {
      if (state[code] && state[code].data && state[code].data.length) {
        return resolve(JSON.parse(JSON.stringify(state[code])));
      }
      publicApi.getDicList(code).then(function (res) {
        if (!res.data || !res.data.length) {
          return reject(res);
        }
        commit('SET_STATE_VAR', [code, res]);
        return resolve(JSON.parse(JSON.stringify(res)));
      });
    });
  }
};
export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};