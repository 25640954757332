import "core-js/modules/es6.regexp.replace";
import "core-js/modules/web.dom.iterable";
/**
 * 全局状态管理
 */
import Vue from 'vue';
import Vuex from 'vuex';
import getters from "./getters";
Vue.use(Vuex);

// https://webpack.js.org/guides/dependency-management/#requirecontext
var modulesFiles = require.context('./modules', true, /\.js$/);

// 不需要“从中导入应用”。/modules/app`
// 它将自动要求模块文件中的所有vuex模块

var modules = modulesFiles.keys().reduce(function (modules, modulePath) {
  // set './app.js' => 'app'
  var moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, '$1');
  var value = modulesFiles(modulePath);
  modules[moduleName] = value.default;
  return modules;
}, {});
var store = new Vuex.Store({
  modules: modules,
  getters: getters
});
export default store;