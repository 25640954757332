import "core-js/modules/es6.object.keys";
import "core-js/modules/web.dom.iterable";
import "/root/workspace/dst3.0_front_vGcT/node_modules/_core-js@2.6.12@core-js/modules/es6.array.iterator.js";
import "/root/workspace/dst3.0_front_vGcT/node_modules/_core-js@2.6.12@core-js/modules/es6.promise.js";
import "/root/workspace/dst3.0_front_vGcT/node_modules/_core-js@2.6.12@core-js/modules/es6.object.assign.js";
import "/root/workspace/dst3.0_front_vGcT/node_modules/_core-js@2.6.12@core-js/modules/es7.promise.finally.js";
import 'babel-polyfill';
import Vue from 'vue';
import Cookies from 'js-cookie';
import 'normalize.css/normalize.css'; // a modern alternative to CSS resets

import Element from 'element-ui';
Element.Dialog.props.closeOnClickModal.default = false; // 修改 el-dialog 默认点击遮照为不关闭

import "./styles/element-variables.scss";
import '@/styles/index.scss'; // 全局css

import App from "./App";
import store from "./store";
import router from "./router";
import moment from 'moment';
import OpenSeadragon from 'openseadragon';
import echarts from 'echarts';
Vue.prototype.$echarts = echarts;
import "./icons"; // icon
import "./permission"; // 权限控制
import "./utils/error-log"; // 错误日志
import "./utils/permission-btn"; // 按钮控制
import preventReClick from "./directive/stopBtn";
Vue.use(preventReClick);
import JsonEditor from 'vue-json-edit';
Vue.use(JsonEditor);
import * as filters from "./filters"; // 全局筛选器
Vue.prototype.$moment = moment; // 日期格式化插件
window.OpenSeadragon = OpenSeadragon;
Vue.use(Element, {
  size: Cookies.get('size') || 'medium' // 设置元素ui默认大小
});

// 注册全局实用程序筛选器
Object.keys(filters).forEach(function (key) {
  Vue.filter(key, filters[key]);
});
Vue.config.productionTip = false;
new Vue({
  el: '#app',
  router: router,
  store: store,
  render: function render(h) {
    return h(App);
  }
});