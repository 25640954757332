import "core-js/modules/es6.regexp.to-string";
import "core-js/modules/es6.regexp.replace";
import "core-js/modules/es6.number.constructor";
/**
 * 全局过滤器
 */
// import parseTime, formatTime and set to filter
export { parseTime, formatTime } from '@/utils';
import moment from 'moment';

/**
 * 如果时间是复数，则显示复数标签
 * @param {number} time
 * @param {string} label
 * @return {string}
 */
function pluralize(time, label) {
  if (time === 1) {
    return time + label;
  }
  return time + label + 's';
}

/**
 * @param {number} time
 */
export function timeAgo(time) {
  var between = Date.now() / 1000 - Number(time);
  if (between < 3600) {
    return pluralize(~~(between / 60), ' minute');
  } else if (between < 86400) {
    return pluralize(~~(between / 3600), ' hour');
  } else {
    return pluralize(~~(between / 86400), ' day');
  }
}

/**
 * 数字格式
 * like 10000 => 10k
 * @param {number} num
 * @param {number} digits
 */
export function numberFormatter(num, digits) {
  var si = [{
    value: 1E18,
    symbol: 'E'
  }, {
    value: 1E15,
    symbol: 'P'
  }, {
    value: 1E12,
    symbol: 'T'
  }, {
    value: 1E9,
    symbol: 'G'
  }, {
    value: 1E6,
    symbol: 'M'
  }, {
    value: 1E3,
    symbol: 'k'
  }];
  for (var i = 0; i < si.length; i++) {
    if (num >= si[i].value) {
      return (num / si[i].value).toFixed(digits).replace(/\.0+$|(\.[0-9]*[1-9])0+$/, '$1') + si[i].symbol;
    }
  }
  return num.toString();
}

/**
 * 10000 => "10,000"
 * @param {number} num
 */
export function toThousandFilter(num) {
  return (+num || 0).toString().replace(/^-?\d+/g, function (m) {
    return m.replace(/(?=(?!\b)(\d{3})+$)/g, ',');
  });
}

/**
 * 大写首字符
 * @param {String} string
 */
export function uppercaseFirst(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

/**
 * 判断默认显示0
 * @param {String} string
 */
export function toZero(string) {
  return !string ? 0 : string;
}

/**
 * 日期格式化为年月日 : 2020年02月24日
 * @param {String} string
 */
export function formatDate(value, formatString) {
  formatString = formatString || 'YYYY-MM-DD';
  return value ? moment(value).format(formatString) : '';
}

/**
 * 日期格式化为年月日时分秒 : 2020年02月24日 16:00
 * @param {String} string
 */
export function formatDateTime(value, formatString) {
  formatString = formatString || 'YYYY-MM-DD HH:mm:ss';
  return value ? moment(value).format(formatString) : '';
}