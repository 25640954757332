import "core-js/modules/es6.function.name";
import request from '@/utils/request';
var projectList = {
  // 校验删除活动类型是否存在样本
  validateRemoveActivityType: function validateRemoveActivityType(activityType) {
    return request({
      url: '/dst-pathology/pathology/sample/validateRemoveActivityType?activityType=' + activityType,
      method: 'get'
    });
  },
  confrimProductGroup: function confrimProductGroup(data) {
    return request({
      url: '/dst-fund/fund/hospital-product-group/save',
      method: 'post',
      data: data
    });
  },
  // 根据医院Id查询关联项目套餐
  getProductGroupDetailById: function getProductGroupDetailById(id) {
    return request({
      url: '/dst-fund/fund/product-group/getOneByHospitalId?hospitalId=' + id,
      method: 'get'
    });
  },
  // 删除项目套餐
  productGroupRemove: function productGroupRemove(id) {
    return request({
      url: '/dst-fund/fund/product-group/removeByIds?ids=' + id,
      method: 'get'
    });
  },
  // 编辑产品项目套餐
  editProductGroup: function editProductGroup(data) {
    return request({
      url: '/dst-fund/fund/product-group/saveOrUpdate',
      method: 'post',
      data: data
    });
  },
  // 产品项目套餐分页
  getProductGroupList: function getProductGroupList(data, page) {
    return request({
      url: '/dst-fund/fund/product-group/pageByProductGroup?size=' + page.size + '&current=' + page.current,
      method: 'post',
      data: data
    });
  },
  // 产品项目套餐新分页
  pageByProductGroupOptimizeApi: function pageByProductGroupOptimizeApi(data, page) {
    return request({
      url: '/dst-fund/fund/product-group/pageByProductGroupOptimize?size=' + page.size + '&current=' + page.current + '&name=' + data.name,
      method: 'get'
    });
  },
  // 根据id产品项目套餐详情
  getDetailByIdApi: function getDetailByIdApi(id) {
    return request({
      url: '/dst-fund/fund/product-group/getOptimizeDetailById?id=' + id,
      method: 'get'
    });
  },
  // 产品项目套餐list
  pageByProductGroupBySearchApi: function pageByProductGroupBySearchApi() {
    return request({
      url: '/dst-fund/fund/product-group/pageByProductGroupBySearch',
      method: 'get'
    });
  },
  // 查询产品和产品详情（产品类型）
  getProjectTypeList: function getProjectTypeList() {
    return request({
      url: '/dst-fund/fund/product/listDetails',
      method: 'get'
    });
  },
  // 查询产品和产品详情（产品类型）【套餐】
  getProjectComboTypeList: function getProjectComboTypeList(isCombo) {
    return request({
      url: '/dst-fund/fund/product/listDetails?isCombo=' + isCombo,
      method: 'get'
    });
  },
  getProjectComboByTypeList: function getProjectComboByTypeList(data) {
    return request({
      url: '/dst-fund/fund/product/listByProduct',
      method: 'post',
      data: data
    });
  },
  // 批量导入
  importProductHospitalPriceApi: function importProductHospitalPriceApi(data) {
    return request({
      url: '/dst-fund/fund/product-hospital-price/importProductHospitalPrice',
      method: 'post',
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      data: data
    });
  },
  // 当前登录经销商查询产品和产品详情（产品类型）
  getListDetailsByCuttingLoginDealerCustomer: function getListDetailsByCuttingLoginDealerCustomer(validateClass) {
    return request({
      url: '/dst-fund/fund/product/listDetailsByCuttingLoginDealerCustomerAndValidateClass?validateClass=' + validateClass,
      method: 'get'
    });
  },
  priceDel: function priceDel(id) {
    return request({
      url: '/dst-fund/fund/product-hospital-price/removeByIds?ids=' + id,
      method: 'get'
    });
  },
  priceSave: function priceSave(data) {
    return request({
      url: '/dst-fund/fund/product-hospital-price/saveOrUpdate',
      method: 'post',
      data: data
    });
  },
  // 医院查询产品和产品详情（产品类型）
  listDetailsByHospitalId: function listDetailsByHospitalId(id) {
    return request({
      url: '/dst-fund/fund/product/listDetailsByHospitalId?hospitalId=' + id,
      method: 'get'
    });
  },
  // 医院项目价格
  priceList: function priceList(data, page) {
    return request({
      url: '/dst-fund/fund/product-hospital-price/pageByProductHospitalPrice?size=' + page.size + '&current=' + page.current,
      method: 'post',
      data: data
    });
  },
  // 导入项目区域价格
  importAreaPrice: function importAreaPrice(data) {
    return request({
      url: '/dst-fund/fund/product-area-price/importProductAreaPrice',
      method: 'post',
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      data: data
    });
  },
  // 套餐管理点击编辑
  getDetailsByIdPackage: function getDetailsByIdPackage(id) {
    return request({
      url: '/dst-fund/fund/product/getDetailsById?id=' + id,
      method: 'get'
    });
  },
  // 项目区域价格查询
  pageByProductAreaPrice: function pageByProductAreaPrice(data, page) {
    return request({
      url: '/dst-fund/fund/product-area-price/pageByProductAreaPrice?size=' + page.size + '&current=' + page.current,
      method: 'post',
      data: data
    });
  },
  // 保存项目子类型别列表
  saveByProductTypeCellRanges: function saveByProductTypeCellRanges(data) {
    return request({
      url: '/dst-fund/fund/product-type-cell-ranges/saveByProductTypeCellRanges',
      method: 'post',
      data: data
    });
  },
  // 项目子类型别列表
  getProductChildrenTypeList: function getProductChildrenTypeList(data) {
    return request({
      url: '/dst-fund/fund/product-type-cell-ranges/listByProductTypeCellRanges',
      method: 'post',
      data: data
    });
  },
  // 删除项目子类
  removeProductChildren: function removeProductChildren(id) {
    return request({
      url: '/dst-fund/fund/product-type/removeByIds?ids=' + id,
      method: 'get'
    });
  },
  // 新增、编辑项目子类
  editProductChildren: function editProductChildren(data) {
    return request({
      url: '/dst-fund/fund/product-type/saveOrUpdate',
      method: 'post',
      data: data
    });
  },
  // 项目子类列表
  getProductChildrenList: function getProductChildrenList(id) {
    return request({
      url: '/dst-fund/fund/product-type/listByProductType?productId=' + id,
      method: 'get'
    });
  },
  // 删除项目信息
  productRemove: function productRemove(id) {
    return request({
      url: '/dst-fund/fund/product/removeByIds?ids=' + id,
      method: 'get'
    });
  },
  // 新增、编辑项目
  confirmEditProduct: function confirmEditProduct(data) {
    return request({
      url: '/dst-fund/fund/product/saveOrUpdate',
      method: 'post',
      data: data
    });
  },
  // 项目分页列表
  getProductList: function getProductList(data, page) {
    return request({
      url: '/dst-fund/fund/product/pageByProduct?size=' + page.size + '&current=' + page.current,
      method: 'post',
      data: data
    });
  },
  // 项目特检配置分页列表
  pageByProductSpecialSurveyApi: function pageByProductSpecialSurveyApi(data, page) {
    return request({
      url: '/dst-fund/fund/product-special-survey/pageByProductSpecialSurvey?size=' + page.size + '&current=' + page.current,
      method: 'post',
      data: data
    });
  },
  // ======================================================================
  // ======================================================================
  // ======================================================================
  // 试剂列表
  getListByReagentApi: function getListByReagentApi(data) {
    return request({
      url: '/dst-fund/business/reagent/listByReagent',
      method: 'post',
      data: data
    });
  },
  // 试剂列表Map
  getMapListByReagentApi: function getMapListByReagentApi(data) {
    return request({
      url: '/dst-fund/business/reagent/mapByReagent',
      method: 'post',
      data: data
    });
  },
  // 试剂分页列表
  getPageByReagentApi: function getPageByReagentApi(data, page) {
    return request({
      url: '/dst-fund/business/reagent/pageByReagent?size=' + page.size + '&current=' + page.current,
      method: 'post',
      data: data
    });
  },
  // 试剂删除
  removeReagentById: function removeReagentById(id) {
    return request({
      url: '/dst-fund/business/reagent/removeById?id=' + id,
      method: 'get'
    });
  },
  // 试剂保存
  saveOrUpdateReagentApi: function saveOrUpdateReagentApi(data) {
    return request({
      url: '/dst-fund/business/reagent/saveOrUpdate',
      method: 'post',
      data: data
    });
  },
  // 套餐试剂保存或修改
  saveOrUpdateByComboApi: function saveOrUpdateByComboApi(data) {
    return request({
      url: '/dst-fund/business/reagent/saveOrUpdateByCombo',
      method: 'post',
      data: data
    });
  },
  // 套餐试剂删除
  removeComboYesByIdApi: function removeComboYesByIdApi(id) {
    return request({
      url: '/dst-fund/business/reagent/removeComboById?id=' + id,
      method: 'get'
    });
  },
  // ======================================================================
  // ======================================================================
  // ======================================================================
  // 项目特检配置保存或修改
  specialSaveOrUpdateApi: function specialSaveOrUpdateApi(data) {
    return request({
      url: '/dst-fund/fund/product-special-survey/saveOrUpdate',
      method: 'post',
      data: data
    });
  },
  // 删除项目特检配置
  specialProductRemoveApi: function specialProductRemoveApi(id) {
    return request({
      url: '/dst-fund/fund/product-special-survey/removeByIds?ids=' + id,
      method: 'get'
    });
  },
  // =====  耗材管理  =====
  // 删除项目信息
  consumablesMove: function consumablesMove(data) {
    return request({
      url: '/dst-fund/fund/consumables/remove?ids=' + data,
      method: 'get'
    });
  },
  // 新增项目信息
  consumablesSave: function consumablesSave(data) {
    return request({
      url: '/dst-fund/fund/consumables/save',
      method: 'post',
      data: data
    });
  },
  // 列表查询
  consumablesList: function consumablesList(data) {
    return request({
      url: '/dst-fund/fund/consumables/list',
      method: 'get',
      params: data
    });
  },
  // 编辑项目信息
  consumablesEdit: function consumablesEdit(data) {
    return request({
      url: '/dst-fund/fund/consumables/edit',
      method: 'post',
      data: data
    });
  },
  // 获取检查项目 ( 含套餐和非套餐 )
  getProductListDetailsApi: function getProductListDetailsApi(data) {
    return request({
      url: '/dst-fund/fund/product/listDetails?isSupportCombo=' + data.isSupportCombo,
      method: 'get'
    });
  },
  // 套餐的保存
  saveOrUpdateByComboItemApi: function saveOrUpdateByComboItemApi(data) {
    return request({
      url: '/dst-fund/fund/product/saveOrUpdateByCombo',
      method: 'post',
      data: data
    });
  },
  // 删除套餐
  removeComboByIdApi: function removeComboByIdApi(data) {
    return request({
      url: '/dst-fund/fund/product/removeComboById?id=' + data,
      method: 'get'
    });
  },
  // 型别调整
  listProductTypeByDealerAndHospitalApi: function listProductTypeByDealerAndHospitalApi(data) {
    return request({
      url: '/dst-fund/fund/product/listProductTypeByDealerAndHospital',
      method: 'get',
      params: data
    });
  },
  // 型别调整保存
  updateBySampleProductTypeapi: function updateBySampleProductTypeapi(data) {
    return request({
      url: '/dst-pathology/pathology/report/updateBySampleProductType',
      method: 'post',
      data: data
    });
  },
  listConsumables: function listConsumables(params) {
    return request({
      url: '/dst-fund/fund/consumables/listConsumables',
      method: 'get',
      params: params
    });
  }
};
export default projectList;