export default {
  install: function install(Vue) {
    // 防重复点击(指令实现)
    Vue.directive('preventReClick', {
      inserted: function inserted(el, binding) {
        el.addEventListener('click', function () {
          if (!el.disabled) {
            // var text = el.getElementsByTagName('span')[0].innerText
            // console.log(text)
            el.disabled = true;
            // el.innerHTML = '请稍等...'
            setTimeout(function () {
              el.disabled = false;
              // el.innerHTML = '确定'
            }, binding.value || 1000);
          }
        });
      }
    });
  }
};