import "core-js/modules/es7.object.get-own-property-descriptors";
import "core-js/modules/es6.object.keys";
import _defineProperty from "/root/workspace/dst3.0_front_vGcT/node_modules/_@babel_runtime-corejs2@7.24.4@@babel/runtime-corejs2/helpers/esm/defineProperty.js";
import "core-js/modules/es6.function.name";
import "core-js/modules/web.dom.iterable";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { constantRoutes } from '@/router';
import { Message } from 'element-ui';
import article from '@/api/article';
import Layout from '@/layout';
/**
 * 使用meta.role确定当前用户是否具有权限
 * @param roles
 * @param route
 */
// function hasPermission(roles, route) {
//   if (route.meta && route.meta.roles) {
//     return roles.some(role => route.meta.roles.includes(role))
//   } else {
//     return true
//   }
// }

// 去重
// function unique(data) {
//   const res = new Map()
//   data = data.filter((a) => !res.has(a.id) && res.set(a.id, 1))
//   data.map((item) => {
//     if (item.children && item.children.length) {
//       item.children = unique(item.children)
//     }
//   })
//   return data
// }
/**
 * 后台查询的菜单数据拼装成路由格式的数据
 * @param routes
 */
export function generaMenu(routes, data) {
  data.forEach(function (item) {
    var menu = {
      path: item.parentId === '0' ? '/' + item.path : item.path,
      // 目前语言版本无法编译路由文件中的“ () => import('@/views/error-page/401')”语法，目前支持“ resolve => require([`@/views/${item.code}/index`], resolve)”
      component: item.parentId === '0' ? Layout : function (resolve) {
        return require(["@/views/".concat(item.code, "/index")], resolve);
      },
      name: item.alias,
      meta: {
        title: item.name,
        icon: item.icon,
        noCache: false
      },
      children: []
    };
    if (item.children) {
      generaMenu(menu.children, item.children);
    }
    routes.push(menu);
  });
}
/**
 * 递归过滤异步路由表
 * @param routes asyncRoutes
 * @param roles
 */
export function filterAsyncRoutes(routes, roles) {
  var res = [];
  routes.forEach(function (route) {
    var tmp = _objectSpread({}, route);
    // if (hasPermission(roles, tmp)) {
    //   if (tmp.children) {
    //     tmp.children = filterAsyncRoutes(tmp.children, roles)
    //   }
    //   res.push(tmp)
    // }
    res.push(tmp);
  });
  return res;
}
var state = {
  routes: [],
  addRoutes: [],
  btnRoutes: []
};
var mutations = {
  SET_ROUTES: function SET_ROUTES(state, routes) {
    state.addRoutes = routes;
    state.routes = constantRoutes.concat(routes);
  },
  SET_BTN_ROUTES: function SET_BTN_ROUTES(state, btnRoutes) {
    state.btnRoutes = btnRoutes;
  }
};
var actions = {
  generateRoutes: function generateRoutes(_ref, payload) {
    var commit = _ref.commit;
    return new Promise(function (resolve) {
      var loadMenuData = [];
      // 先查询后台并返回左侧菜单数据并把数据添加到路由
      article.getListMenuTreeByUserIdApi().then(function (response) {
        var data = response;
        if (response.code !== 200) {
          Message.error(response.msg || 'Has Error');
        } else {
          // data = unique(response.data)
          data = response.data;
          // console.log(data)
          Object.assign(loadMenuData, data);
          localStorage.setItem('loadMenuData', loadMenuData);
          var asyncRoutes = [];
          generaMenu(asyncRoutes, loadMenuData);
          // let accessedRoutes
          // if (roles.includes('admin')) {
          //   accessedRoutes = asyncRoutes || []
          // } else {
          //   accessedRoutes = filterAsyncRoutes(asyncRoutes, roles)
          // }
          var accessedRoutes = filterAsyncRoutes(asyncRoutes);
          commit('SET_ROUTES', accessedRoutes);
          sessionStorage.setItem('get-btn-routes', JSON.stringify(data));
          resolve(accessedRoutes);
        }
      }).catch(function (error) {
        console.log(error);
      });
    });
  }
};
export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};