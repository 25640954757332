import projectList from '@/api/projectList';
var state = {
  projectList: []
};
var mutations = {
  SET_PROJECT: function SET_PROJECT(state, projectList) {
    state.projectList = projectList;
  }
};
var actions = {
  loadProject: function loadProject(_ref, page) {
    var commit = _ref.commit;
    projectList.productList(page).then(function (res) {
      if (res && res.data.records.length > 0) {
        var checkProjectList = res.data.records;
        localStorage.setItem('projectList', JSON.stringify(checkProjectList));
        commit('SET_PROJECT', checkProjectList);
      }
    });
  }
};
export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};