import request from '@/utils/request';
var publicApi = {
  // 字典
  getDicList: function getDicList(code) {
    return request({
      url: '/dst-system/system/dict/listTree?code=' + code,
      method: 'get'
    });
  },
  // 区域
  getAreaList: function getAreaList(params) {
    return request({
      url: '/dst-fund/fund/area/list',
      method: 'get',
      params: params
    });
  },
  getAreaListById: function getAreaListById(parentId) {
    return request({
      url: '/dst-fund/fund/area/selectListById?parentId=' + parentId,
      method: 'get'
    });
  },
  // 销售负责人
  getSalesList: function getSalesList(data) {
    return request({
      url: '/dst-system/system/user/pageBySalesUser',
      method: 'get',
      params: data
    });
  },
  getTechnologyType: function getTechnologyType(data) {
    return request({
      url: '/dst-pathology/pathology/sample-report-result/getDiagosisTypeBySampleId',
      method: 'get',
      params: data
    });
  },
  // 相关方列表
  pageByCustomerIncomeAnalysis: function pageByCustomerIncomeAnalysis(data, page) {
    return request({
      url: "/dst-system/system/customer/pageByCustomerIncomeAnalysis?size=".concat(page.size, "&current=").concat(page.current),
      method: 'post',
      data: data
    });
  },
  pageByCustomerUser: function pageByCustomerUser(params) {
    return request({
      url: "/dst-system/system/customer/pageByCustomerUser",
      method: 'get',
      params: params
    });
  },
  pageCoBulidAndCentreByCustomerUser: function pageCoBulidAndCentreByCustomerUser(params) {
    return request({
      url: "/dst-system/system/customer/pageCoBulidAndCentreByCustomerUser",
      method: 'get',
      params: params
    });
  },
  // 图片上传获取url路径
  uploadSampleScreenFileList: function uploadSampleScreenFileList(data) {
    return request({
      url: 'dst-file/file/uploadFileListReturnUrlByModule',
      method: 'post',
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      data: data
    });
  }
};
export default publicApi;